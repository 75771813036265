body{
	font-family: 'Noto Sans', sans-serif;
	font-family: 'Open Sans', sans-serif;
	background-color: #f7f7f7;
}

.navbar-dark{
	color: #ffffff;
	z-index: 3;
	letter-spacing: 2px;
}


/* SIDE MENU */
.sidenav {
	height: 100%;
	width: 20vw;
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	background-color: #ffffff;
	border-right: 0.2rem solid #f0f0f0;
	overflow-x: hidden;
	padding-top: 5.5rem;
	transition: 0.5s;
}

.sidenav a {
	padding: 0.5rem 2rem;
	text-decoration: none;
	font-size: 1rem;
	color: #757575;
	display: block;
	transition: 0.3s;
	margin-top: 0.5rem;
}


.sidenav a:hover, .sidenav a.active:hover {
	color: #ffffff;
	background-color: #343a40;
	font-weight: bold;
}

.sidenav a.active {
	font-weight: bolder;
	background-color: #343a40;
	color: #ffffff;
}


.sidenav .closebtn {
	position: absolute;
	top: 0;
	right: 25px;
	font-size: 36px;
	margin-left: 50px;
}

.main {
	transition: margin-left .5s;
	margin-left: 20vw;
	background-color: #f7f7f7;
	position: relative;
	height: 100%;
}

@media screen and (max-height: 450px) {
	.sidenav {padding-top: 15px;}
	.sidenav a {font-size: 18px;}
}
/* END SIDE MENU */

hr{
	opacity: 0.1;
}

.loading-box{
	position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10;
    background: rgba(0,0,0,0.6);
}

.spinner-border{
	height: 4rem;
	width: 4rem;
}

.loading-mini-box{
	border-radius: 1rem;
	background: rgba(230, 230, 230,1);
}

.PROMOTOR .card-header{
	background-color: #962d5d;
	color: #ffffff;
}

.CONVENCIDO .card-header{
	background-color: #185710;
	color: #ffffff;
}

.PROMOTOR{
	border-color: #962d5d;
}

.CONVENCIDO{
	border-color: #185710;
}

.card h5{
	font-size: 1rem;
	letter-spacing: 1px;
}

.card{
	font-size: 0.85rem;
}

.data-table-extensions{
	background-color: #962d5d;
}

.data-table-extensions > .data-table-extensions-action > button{
	background-color: #ffffff !important;
	margin-bottom: 0 !important;
}

.data-table-extensions > .data-table-extensions-filter > .icon{
	background-color: #ffffff;
	border-radius: 2rem;
}

.rdt_TableHeadRow{
	font-weight: bold;
}

.filter-text{
	color: #ffffff;
}

.table-border{
	border:  1px solid #c3c3c3;
	background-color: #ffffff;
}

/* width */
.table-border ::-webkit-scrollbar {
  width: 10px;
  height: 1rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c3c3c3;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #962d5d;
}


@media only screen and (max-width: 1024px){
	.sidenav{
		height: auto;
		width: 100%;
		position: relative;
		padding-top: 0;
	}

	.main {
		margin-left: 0;
	}

}

.req{
	color: red;
	font-weight: bold;
}

.z-10{
	position: relative;
	z-index: 2;
}